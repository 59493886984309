import { Card, CardContent, Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import IMAGES from "../../../images";
import LoginForm from "../../components/data-entry/LoginForm";
import { LoginFormFields } from "../../../domain/models/data-entry/loginFormFields";
import { LoginUser } from "../../../domain/usages/data-entry/login";
import Swal from "sweetalert2";
import { LoggedInUser } from "../../../domain/usages/logged-in-user";
import { useNavigate } from "react-router-dom";
import { pageRoutes } from "../../../routes";

type Props = {
  userLogin: LoginUser;
  loggedInUser: LoggedInUser;
};

const LoginPage: React.FC<Props> = ({ userLogin, loggedInUser }) => {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleUserLogin = async (data: LoginFormFields) => {
    let payload = {
      username: data.email,
      password: data.password.toString(),
    };
    setLoading(true);

    let result = await userLogin.login(payload);
    if (result.success) {
      loggedInUser.setLoggedInUser(result.data);
      loggedInUser.setToken(result.data.token);
      if (result.data.isAdmin) {
        navigate(pageRoutes.doctorsCountSummary);
      } else {
        navigate(pageRoutes.doctorVerification);
      }

      setLoading(false);
      Swal.fire({
        icon: "success",
        title: "User Logged In Successfully",
        showConfirmButton: false,
        timer: 3000,
      });
    } else if (result.statusCode === 404) {
      setLoading(false);
      Swal.fire(result.message[0].message, "", "error");
    } else if (result.type === "E401_UNAUTHORIZED") {
      setLoading(false);
      Swal.fire(result.message, "", "error");
    } else {
      setLoading(false);
      if (result.errors) {
        if (
          typeof result.errors.message === "object" &&
          result.errors.message !== null
        ) {
          Swal.fire(result.errors.message.message, "", "error");
        } else if (Array.isArray(result.errors)) {
          let errorText = "";
          for (let x in result.errors) {
            errorText += result.errors[x].message + "\n";
          }
          Swal.fire(errorText, "", "error");
        } else {
          Swal.fire(result.errors.message, "", "error");
        }
      }
    }
  };
  return (
    <Grid container spacing={10}>
      <Grid item xs={6}>
        <img src={IMAGES.DoctorTeam} height={"100%"} />
      </Grid>
      <Grid item xs={6} height={"90vh"} display={"grid"} alignItems={"center"}>
        <Card
          raised
          sx={{
            width: "80%",
            marginTop: 10,
            justifyContent: "center",
          }}
        >
          <CardContent>
            <Stack justifyContent={"center"} spacing={5} alignItems={"center"}>
              <Stack direction={"column"} alignItems={"center"}>
                <img src={IMAGES.CompanyLogo} width={200} />
                <Typography variant="h4" fontFamily={"cursive"} marginTop={2}>
                  Doctor Verification Portal
                </Typography>
              </Stack>

              <LoginForm sucessCallback={handleUserLogin} loading={loading} />
            </Stack>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default LoginPage;
